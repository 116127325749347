import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { builder, BuilderComponent } from '@builder.io/react';
import '../../static/css/funnel.css';
import { FunnelProvider } from '../context/FunnelContext';
import loadable from '@loadable/component';

const Helmet = loadable(() => import('react-helmet'));

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = 'PetLab Co.';
const defaultDescription = 'Supplies, Treats, Snacks & Chews - Join The Pack';

function SlideTemplate(props) {
  const {
    data,
    path,
    location: { href: funnelUrl }
  } = props;
  const slideContent = data.allBuilderModels.oneSlide.content;
  const productSelectorData = data.funnelPageData?.productSelector;

  const [currentCategory, setCurrentCategory] = useState('subscriptions');
  const [currentProduct, setCurrentProduct] = useState('subscription_0');
  const [tubIndex, setTubIndex] = useState(0);
  const [currentTubIndex, setCurrentTubIndex] = useState(0);
  const [frequency, setFrequency] = useState('1:month');
  const [bumpOfferChecked, setBumpOfferChecked] = useState(false);
  const [bumpOfferIndex, setBumpOfferIndex] = useState(0);
  const [currentTub, setCurrentTub] = useState({});
  const [showSavingsPopup, setShowSavingsPopup] = useState(false);
  const [currentLayer, setCurrentLayer] = React.useState(`layer-0`);
  const [previousLayer, setPreviousLayer] = React.useState([]);
  const [answers, setAnswers] = React.useState({});
  const [extraObjects, setExtraObjects] = useState({
    ...JSON.parse(productSelectorData?.extra)
  });

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(url);
    const selection = parseInt(urlParams.get('sel'));
    const options = [1, 2, 3, 4, 5, 6, 7, 8];
    if (selection && options.includes(selection)) {
      setTubIndex(
        selection <= 4 ? parseInt(selection - 1) : parseInt(selection - 5)
      );
      setCurrentTubIndex(
        selection <= 4 ? parseInt(selection - 1) : parseInt(selection - 5)
      );
      setCurrentCategory(selection <= 4 ? 'subscriptions' : 'onetime');
      setCurrentProduct(
        selection <= 4
          ? `subscription_${parseInt(selection - 1)}`
          : `onetime_${parseInt(selection - 5)}`
      );
    }
  }, []);

  const context = {
    ...productSelectorData,
    currentCategory: currentCategory,
    setCurrentCategory: setCurrentCategory,
    currentProduct: currentProduct,
    setCurrentProduct: setCurrentProduct,
    path: path,
    funnelUrl: funnelUrl,
    pageDesign: 'design1',
    tubIndex: tubIndex,
    setTubIndex: setTubIndex,
    currentTubIndex: currentTubIndex,
    setCurrentTubIndex: setCurrentTubIndex,
    frequency: frequency,
    setFrequency: setFrequency,
    bumpOfferChecked: bumpOfferChecked,
    setBumpOfferChecked: setBumpOfferChecked,
    bumpOfferIndex: bumpOfferIndex,
    setBumpOfferIndex: setBumpOfferIndex,
    currentTub: currentTub,
    setCurrentTub: setCurrentTub,
    showSavingsPopup: showSavingsPopup,
    setShowSavingsPopup: setShowSavingsPopup,
    currentLayer: currentLayer,
    setCurrentLayer: setCurrentLayer,
    previousLayer: previousLayer,
    setPreviousLayer: setPreviousLayer,
    answers: answers,
    setAnswers: setAnswers,
    extraObjects: extraObjects,
    setExtraObjects: setExtraObjects
  };

  return (
    <FunnelProvider value={context}>
      <Helmet
        bodyAttributes={{
          class: `petlab-slide`
        }}
        htmlAttributes={{
          lang: 'en'
        }}
      >
        <title>
          {(slideContent && slideContent.data?.title) || defaultTitle}
        </title>
        <meta
          name="description"
          content={
            (slideContent && slideContent.data?.description) ||
            defaultDescription
          }
        />
        <meta name="funnel-id" content={slideContent.id} />
        <meta
          name="product-selector-id"
          content={slideContent.data.products.id}
        />
        <meta
          name="magic-key"
          content="t54cdW3cbU45TWPRtYGUR4fyBnWHe49jWeuqdRStfAvELctRyfyXLmDteF9Cf9jhPsyzDfkcCK5DNU6Pr87HU8tgNKBDDmeHDFQn"
        />
      </Helmet>
      {/** name of the model is landing page, change it if you decided to build*/}
      <BuilderComponent
        name="slide"
        content={slideContent}
        data={{
          ...context
        }}
      ></BuilderComponent>
    </FunnelProvider>
  );
}

export const query = graphql`
  query($funnelId: String) {
    allBuilderModels {
      oneSlide(query: { id: $funnelId }, options: { cachebust: true }) {
        content
        data {
          title
          description
        }
      }
    }

    funnelPageData(funnelId: { eq: $funnelId }) {
      ...FunnelDataFragment
    }
  }
`;

export default SlideTemplate;
